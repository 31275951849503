/* You can add global styles to this file, and also import other style files */
html,body {
  margin: 0;
  height: 100%;
}
body {
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-family: Roboto;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button.button-label-below .mat-button-wrapper {
  display: grid;
  grid-template-rows: 24px 18px;
  line-height: 20px;
  justify-items: center;
}

button.largebutton {
  width: 100%;
  height: 100px;
  font-size: 2em;
  border:none;
  border-radius: 8px;
  box-shadow: 0 3px 1px -2px rgba(black,.2),
    0 2px 2px 0 rgba(black,.14),
    0 1px 5px 0 rgba(black,.12);
  cursor: pointer;
}

.mat-icon-button .mat-button-wrapper {
  display: grid;
  grid-template-rows: auto auto;
  justify-content: center;
  text-align: center;
  .mat-icon {
    margin: 0 auto;
  }
  span {
    max-height: 24px;
    line-height: 20px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 8px 0;
}
p {
  margin: 0;
  margin-bottom: 10px;
}
.spacer {
  flex: 1;
}

.space-bottom {
  margin-bottom: 24px;
}

// .content {
//   margin: 82px auto 32px;
//   padding: 0 16px;
//   max-width: 960px;
//   box-sizing: border-box;
//   overflow-x: hidden;
// }
svg.material-icons {
  height: 24px;
  width: auto;
}
svg.material-icons:not(:last-child) {
  margin-right: 8px;
}

a,
a:visited,
a:hover {
  color: #1976d2;
  text-decoration: none;
}
a:hover {
  color: #125699;
}
footer {
  margin-top: 8px;
  display: flex;
  align-items: center;
  line-height: 20px;
}
footer a {
  display: flex;
  align-items: center;
}
svg#clouds {
  position: fixed;
  bottom: -160px;
  left: -230px;
  z-index: -10;
  width: 1920px;
}
@media screen and (max-width: 767px) {

}
@media screen and (max-width: 575px) {
  .content {
    padding-left: 6px;
    padding-right: 6px;
  }
}



@media print {
  body {
    font-size: 12pt;
  }
  .print-hidden {
    display: none !important;
  }
}
